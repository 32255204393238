























































































import Vue from "vue";
import { mapGetters } from "vuex";
import Loading from "./views/LoadingPage.vue";
import Login from "./views/Login.vue";

export default Vue.extend({
  name: "App",

  components: {
    Loading,
    Login
  },

  data: () => ({
    drawer: false,
    group: null,
    routes: [
      { name: 'Página Inicial', icon: "mdi-home", link: '/home', roles: [] },
      { name: 'Ofertas', icon: "mdi-shopping", link: '/offers', roles: [] },
      { name: 'Amazon', icon: "mdi-toolbox-outline", link: '/amazon', roles: [] },
      { name: 'Stories', icon: "mdi-clipboard-text-clock-outline", link: '/stories', roles: [] },
      { name: 'Coleções', icon: "mdi-bookmark-box-multiple-outline", link: '/collections', roles: [] },
      { name: 'Destaques', icon: "mdi-marker", link: '/highlights', roles: [] },
      { name: 'Categorias', icon: "mdi-shape", link: '/categories', roles: [] },
      { name: 'Ofertas Relâmpago', icon: "mdi-flash", link: '/lightning-offers', roles: [] },
      { name: 'Códigos da Magalu', icon: "mdi-barcode", link: '/magalu-codes', roles: [] },

      { name: 'Sel. Usuários', icon: "mdi-account-multiple", link: '/users', roles: [] },
      { name: 'Sel. Canais do Telegram', icon: "mdi-send-circle", link: '/telegram-channels', roles: [] },
      { name: 'Sel. Disparar Ofertas', icon: "mdi-pistol", link: '/trigger-offers', roles: [] },
      { name: 'Sel. Agenda de Ofertas', icon: "mdi-calendar-outline", link: '/offers-schedule', roles: [] },

      { name: 'Elise P. - Notificações', icon: "mdi-message-fast", link: '/telegram-notifications', roles: [] },
      { name: 'Elise P. - Estatísticas', icon: "mdi-finance", link: '/elise-statistics', roles: [] }
    ]
  }),

  computed: {
    ...mapGetters({
      alerts: "app/getAlerts",
      loading: "auth/getLoading",
      user: "auth/getUser"
    })
  },

  methods: {
    goTo(path: string) {
      this.$router.push(path)
    }
  },
});

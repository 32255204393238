import { Module } from "vuex"
import firebase from "firebase"
import { RootState, Story, StoriesState } from "../types"

const FIREBASE_COLL = process.env.VUE_APP_STORIES

const storiesModule: Module<StoriesState, RootState> = {
    namespaced: true,
    state: {
        stories: []
    },

    mutations: {
        setStories(state: any, payload: Array<Story>) {
            state.stories = payload
        }
    },

    actions: {
        fetch({ commit }) {
            const colRef = firebase.firestore().collection(FIREBASE_COLL)
            colRef
              .orderBy("expiring_at", "asc")
              .limit(50)
              .get()
              .then(data => {
                  const stories: Array<any> = []
                  data.forEach(story => {
                      stories.push(story.data())
                  })
                  commit("setStories", stories)
              })
        },

        create({ dispatch }, payload: Story) {
            this.dispatch("app/showIsLoading")
            const colRef = firebase.firestore().collection(FIREBASE_COLL)
            payload["id"] = colRef.doc().id

            colRef
              .doc(payload.id)
              .set(payload)
              .then(() => {
                  this.dispatch("app/showIsSuccess")
                  dispatch("fetch")
              })
              .catch(() => {
                  this.dispatch("app/showIsError")
              })
        },

        update({ dispatch }, payload: Story) {
            this.dispatch("app/showIsLoading")
            const colRef = firebase.firestore().collection(FIREBASE_COLL)
            colRef
              .doc(payload.id)
              .update(payload)
              .then(() => {
                  this.dispatch("app/showIsSuccess")
                  dispatch("fetch")
              })
              .catch(() => {
                  this.dispatch("app/showIsError")
              })
        },

        delete({ dispatch }, payload: Story) {
            this.dispatch("app/showIsLoading")
            const colRef = firebase.firestore().collection(FIREBASE_COLL)
            colRef
              .doc(payload.id)
              .delete()
              .then(() => {
                  this.dispatch("app/showIsSuccess")
                  dispatch("fetch")
              })
              .catch(() => {
                  this.dispatch("app/showIsError")
              })
        }
    },

    getters: {
        getStories: function(state: any): Array<Story> {
            return state.stories
        }
    }
}

export default storiesModule

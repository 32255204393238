import { Module } from "vuex"
import firebase from "firebase"
import { RootState, Offer } from "../types"

const offerModule: Module<any, RootState> = {
    namespaced: true,

    state: {
        activeOffers: [],
        inactiveOffers: []
    },

    mutations: {
        setActive(state: any, payload: Offer) {
            state.activeOffers = payload
        },
        setInactive(state: any, payload: Offer) {
            state.inactiveOffers = payload
        }
    },

    actions: {
        fetchActive({ commit }) {
            const colRef = firebase.firestore().collection("amazon")
            colRef
                .where("active", "==", true)
                .get()
                .then(data => {
                    const offers: Array<any> = []
                    data.forEach(offer => {
                        offers.push(offer.data())
                    })
                    commit("setActive", offers)
                })
        },

        fetchInactive({ commit }) {
            const colRef = firebase.firestore().collection("amazon")
            colRef
                .where("active", "==", false)
                .get()
                .then(data => {
                    const offers: Array<any> = []
                    data.forEach(offer => {
                        offers.push(offer.data())
                    })
                    commit("setInactive", offers)
                })
        },

        create({ dispatch }, payload: Offer) {
            this.dispatch("app/showIsLoading")
            const colRef = firebase.firestore().collection("amazon")
            payload["id"] = colRef.doc().id

            colRef
                .doc(payload.id)
                .set(payload)
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetchActive")
                    dispatch("fetchInactive")
                })
                .catch(() => {
                    this.dispatch("app/showIsError")
                })
        },

        update({ dispatch }, payload: Offer) {
            this.dispatch("app/showIsLoading")
            const colRef = firebase.firestore().collection("amazon")
            colRef
                .doc(payload.id)
                .update(payload)
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetchActive")
                    dispatch("fetchInactive")
                })
                .catch(() => {
                    this.dispatch("app/showIsError")
                })
        },

        delete({ dispatch }, payload: Offer) {
            this.dispatch("app/showIsLoading")
            const colRef = firebase.firestore().collection("amazon")
            colRef
                .doc(payload.id)
                .delete()
                .then(() => {
                    this.dispatch("app/showIsSuccess")
                    dispatch("fetchActive")
                    dispatch("fetchInactive")
                })
                .catch(() => {
                    this.dispatch("app/showIsError")
                })
        }
    },

    getters: {
        getActive: (state: any): Array<Offer> => state.activeOffers,
        getInactive: (state: any): Array<Offer> => state.inactiveOffers
    }
}

export default offerModule

import { Module } from "vuex"
import firebase from "firebase"
import { RootState, TelegramNotification, TelegramNotificationState } from "../types"

const FIREBASE_COLL = process.env.VUE_APP_TELEGRAM_NOTIFICATIONS

const telegramNotificationsModule: Module<TelegramNotificationState, RootState> = {
  namespaced: true,
  state: {
    notifications: []
  },

  mutations: {
    setNotifications(state: TelegramNotificationState, payload: Array<TelegramNotification>) {
      state.notifications = payload
    }
  },

  actions: {
    fetch({ commit }) {
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      colRef
        .orderBy("created_at", "desc")
        .limit(20)
        .get()
        .then(data => {
          const notifications: Array<any> = []
          data.forEach(notification => {
            notifications.push(notification.data())
          })
          commit("setNotifications", notifications)
        })
    },

    create({ dispatch }, payload: TelegramNotification) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      payload["id"] = colRef.doc().id

      colRef
        .doc(payload.id)
        .set(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetch")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },
  },

  getters: {
    getNotifications: function(state: TelegramNotificationState): Array<TelegramNotification> {
      return state.notifications
    }
  }
}

export default telegramNotificationsModule

import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: "*",
    redirect: "/home"
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Página Inicial",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/offers",
    name: "Offers",
    component: () => import("../views/Offers/Offers.vue")
  },
  {
    path: "/amazon",
    name: "Amazon",
    component: () => import("../views/Amazon/Amazon.vue")
  },
  {
    path: "/stories",
    name: "Stories",
    component: () => import("../views/Stories/Stories.vue")
  },
  {
    path: "/collections",
    name: "Coleções",
    component: () => import("../views/Collections/Collections.vue")
  },
  {
    path: "/highlights",
    name: "Destaques",
    component: () => import("../views/Highlights/Highlights.vue")
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/Category/Category.vue")
  },
  {
    path: "/magalu-codes",
    name: "Códigos da Magalu",
    component: () => import("../views/Codes/Codes.vue")
  },
  {
    path: "/lightning-offers",
    name: "Ofertas Relâmpago",
    component: () => import("../views/LightningOffers/LightningOffers.vue")
  },
  {
    path: "/users",
    name: "Usuários",
    component: () => import("../views/Users/Users.vue")
  },
  {
    path: "/telegram-channels",
    name: "Canais do Telegram",
    component: () => import("../views/TelegramChannels/TelegramChannels.vue")
  },
  {
    path: "/telegram-notifications",
    name: "Notificações do Telegram",
    component: () => import("../views/TelegramNotifications/TelegramNotifications.vue")
  },
  {
    path: "/elise-statistics",
    name: "Elise P. - Estatísticas",
    component: () => import("../views/EliseStatistics/EliseStatistics.vue")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (!localStorage.getItem('token')) {
//       next('/login')
//       return
//     }
//   }
//   next()
// })

export default router

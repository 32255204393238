import Vue from "vue"
import Vuex from "vuex"

import config from "../config/firebase.config"
import * as Firebase from "firebase/app"
Firebase.initializeApp(config)

import app from "@/store/modules/app"
import auth from "@/store/modules/auth"
import offer from "@/store/modules/offers"
import category from "@/store/modules/category"
import amazon from "@/store/modules/amazon"
import collections from "@/store/modules/collections";
import stories from "@/store/modules/stories";
import uploader from "@/store/modules/uploader";
import telegramChannels from "@/store/modules/telegram-channels";
import telegramNotifications from "@/store/modules/telegram-notifications";
import codes from "@/store/modules/codes";
import eliseStatistics from "@/store/modules/elise-statistics";

import { RootState } from './types'

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  modules: {
    app,
    auth,
    offer,
    category,
    amazon,
    collections,
    stories,
    uploader,
    telegramChannels,
    telegramNotifications,
    codes,
    eliseStatistics
  }
})

store.dispatch("auth/fetchUser")

export default store

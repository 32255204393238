import { Module } from "vuex"
import firebase from "firebase"
import { RootState, CategoryState, Category } from "../types"

const categoryModule: Module<CategoryState, RootState> = {
  namespaced: true,
  state: {
    activeCategories: [],
    inactiveCategories: []
  },

  mutations: {
    setActiveCategories(state: any, payload: Category) {
      state.activeCategories = payload
    },
    setInactiveCategories(state: any, payload: Category) {
      state.inactiveCategories = payload
    }
  },

  actions: {
    fetchActiveCategory({ commit }) {
      let colRef = firebase.firestore().collection("categories")
      colRef
        .where("active", "==", true)
        .get()
        .then(data => {
          let categories: Array<any> = []
          data.forEach(category => {
            categories.push(category.data())
          })
          commit("setActiveCategories", categories)
        })
    },

    fetchInactiveCategory({ commit }) {
      let colRef = firebase.firestore().collection("categories")
      colRef
        .where("active", "==", false)
        .get()
        .then(data => {
          let categories: Array<any> = []
          data.forEach(category => {
            categories.push(category.data())
          })
          commit("setInactiveCategories", categories)
        })
    },

    createCategory({ dispatch }, payload: Category) {
      this.dispatch("app/showIsLoading")
      let colRef = firebase.firestore().collection("categories")
      payload["docId"] = colRef.doc().id

      colRef
        .doc(payload.docId)
        .set(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetchActiveCategory")
          dispatch("fetchInactiveCategory")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    updateCategory({ dispatch }, payload: Category) {
      this.dispatch("app/showIsLoading")
      let colRef = firebase.firestore().collection("categories")
      colRef
        .doc(payload.docId)
        .update(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetchActiveCategory")
          dispatch("fetchInactiveCategory")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    deleteCategory({ dispatch }, payload: Category) {
      this.dispatch("app/showIsLoading")
      let colRef = firebase.firestore().collection("categories")
      colRef
        .doc(payload.docId)
        .delete()
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetchActiveCategory")
          dispatch("fetchInactiveCategory")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    }
  },

  getters: {
    getActiveCategories: function(state: any): Array<Category> {
      return state.activeCategories
    },
    getInactiveCategories: function(state: any): Array<Category> {
      return state.inactiveCategories
    }
  }
}

export default categoryModule

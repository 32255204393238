import { Module } from "vuex"
import firebase from "firebase"
import { RootState, EliseStats, EliseStatsState } from "../types"

const FIREBASE_COLL = process.env.VUE_APP_ELISE_POM_STAT

const eliseStatisticsModule: Module<EliseStatsState, RootState> = {
  namespaced: true,
  state: {
    stats: []
  },

  mutations: {
    setStats(state: EliseStatsState, payload: Array<EliseStats>) {
      state.stats = payload
    }
  },

  actions: {
    fetch({ commit }) {
      const colRef = firebase.firestore().collection(FIREBASE_COLL)
      colRef
        .orderBy("created_at", "desc")
        .limit(10)
        .get()
        .then(data => {
          const stats: Array<any> = []
          data.forEach(stat => {
            stats.push(stat.data())
          })
          commit("setStats", stats)
        })
    },
  },

  getters: {
    getStats: function(state: EliseStatsState): Array<EliseStats> {
      return state.stats
    }
  }
}

export default eliseStatisticsModule

import { Module } from "vuex"
import firebase from "firebase"
import { RootState, OffersState, Offer, OfferBySKU } from "../types"

const offerModule: Module<OffersState, RootState> = {
  namespaced: true,
  state: {
    activeOffers: [],
    lightningOffers: []
  },

  mutations: {
    setActiveOffers(state: any, payload: Offer) {
      state.activeOffers = payload
    },
    setLightningOffers(state: any, payload: Array<Offer>) {
      state.lightningOffers = payload
    }
  },

  actions: {
    fetchActiveOffers({ commit }) {
      const colRef = firebase.firestore().collection("offers")
      colRef
        .where("active", "==", true)
        .limit(10)
        .get()
        .then(data => {
          const offers: Array<any> = []
          data.forEach(offer => {
            offers.push(offer.data())
          })
          commit("setActiveOffers", offers)
        })
    },

    createOffer({ dispatch }, payload: Offer) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection("offers")
      payload["id"] = colRef.doc().id

      colRef
        .doc(payload.id)
        .set(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetchActiveOffers")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    updateOffer({ dispatch }, payload: Offer) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection("offers")
      colRef
        .doc(payload.id)
        .update(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetchActiveOffers")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    deleteOffer({ dispatch }, payload: Offer) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection("offers")
      colRef
        .doc(payload.id)
        .delete()
        .then(() => {
          this.dispatch("app/showIsSuccess")
          dispatch("fetchActiveOffers")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    triggerLightningOffer({ dispatch }, payload: OfferBySKU) {
      this.dispatch("app/showIsLoading")
      const colRef = firebase.firestore().collection("lightning_offer_ids")
      payload["id"] = colRef.doc().id

      colRef
        .doc(payload.id)
        .set(payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
        })
        .catch(() => {
          this.dispatch("app/showIsError")
        })
    },

    fetchLightningOffer({ commit }) {
      const colRef = firebase.firestore().collection("lightning_offer")
      colRef
        .orderBy("updatedAt", "desc")
        .limit(10)
        .get()
        .then(data => {
          const offers: Array<any> = []
          data.forEach(offer => {
            offers.push(offer.data())
          })
          commit("setLightningOffers", offers)
        })
    },
  },

  getters: {
    getActiveOffers: (state: any): Array<Offer> => state.activeOffers,
    getLightningOffers: (state: any): Array<Offer> => state.lightningOffers
  }
}

export default offerModule
